$(document).ready(function() {

  var isAnimated = false;
  $(".section__homework-block > .practice").on("click", function(e) {
    e.preventDefault();
    if(isAnimated === false) {
      isAnimated = true;
      if($(this).hasClass("open")) {

        var par = $(this).parent();
        $(".section__homework", par).hide(500);
        $(this).removeClass("open");
        setTimeout(function(){
          isAnimated = false;
        }, 500);
      }
      else {
        isAnimated = true;
        $(".section__homework").hide();
        $(".section__homework-block > .practice").removeClass("open");
        var par = $(this).parent();

        $(".section__homework", par).show(500);

        $(this).addClass("open");
        setTimeout(function(){
          isAnimated = false;
        }, 500);
      }
    }

  });

  $(".section__show img").on("click", function(e) {

    if($(this).hasClass("open")) {
      $(this).removeClass("open");
      $(this).parent().next().slideUp(300);
      $(this).removeClass('rotate');
    }  else {
      $(this).addClass("open");
      $(this).parent().next().slideDown(300);
      $(this).addClass('rotate');

    }


  });



});